/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #0a2540;
$primary: #1c2528;
$secondary: #6b6b6b;
$ternary: #b5b5b5;
$border: #e5e5e5;
$gray: #f4f6f9;
$accent: #6990ff;
$accent2: #52c8c4;
