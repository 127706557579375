/*--------------------------------------------------------------
6. Header
----------------------------------------------------------------*/
.cs-site_header {
  position: relative;
  z-index: 101;
  .cs-btn.cs-style6 {
    font-size: 16px;
  }
}

.cs-site-branding {
  display: inline-block;
  max-width: 180px;
}

.cs-site_header.cs-style1 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  transition: all 0.4s ease;

  .cs-main_header_in {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 80px;
    position: relative;
  }
  .cs-main_header_right {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
.cs-toolbox_icon_btn {
  cursor: pointer;
}
.cs-site_header.cs-style1 {
  &.cs-sticky-active {
    background-color: $white;
    box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
  }

  .cs-toolbox {
    display: flex;
    align-items: center;
    > * {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
  .cs-nav + .cs-toolbox {
    margin-left: 40px;
  }

  .cs-action_box {
    display: flex;
    align-items: center;
    .cs-action_value {
      margin-left: 15px;
    }
    > * {
      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }
  .cs-btn {
    span {
      padding: 10px 15px;
    }
  }
}

.cs-toolbox {
  > *:not(:first-child) {
    margin-left: 20px;
  }
}

.cs-toolbox_btn {
  border-radius: 5px;
  color: #fefefe;
  font-size: 16px;
  display: inline-block;
  padding: 11px 30px;
  min-width: 135px;
  text-align: center;
  font-weight: 500;
  line-height: 1.5em;
  box-shadow: 0px 10px 40px 0px rgba(59, 20, 0, 0.1);
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }
}

.cs-site_header_full_width .container {
  max-width: 100%;
  padding: 0 50px;
}

.cs-site_header_style1 {
  border-bottom: 1px solid #1a188d;

  .cs-main_header_right {
    display: flex;
    align-items: center;
  }

  .cs-nav + .cs-header_toolbox.cs-center {
    margin-left: 35px;
  }

  .cs-nav .cs-nav_list > li.current-menu-item > a:before {
    bottom: -4px;
    background-color: $white;
  }
}

@media screen and (max-width: 1199px) {
  .cs-main_header .container {
    max-width: 100%;
  }

  .cs-site_header.cs-style1 {
    .cs-nav {
      display: flex;
    }
  }
}

.cs-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cs-site_header.cs-sticky-header {
  position: fixed;
  z-index: 200;
  width: 100%;
  top: 0;
  left: 0;
}

.cs-site_branding {
  display: inline-block;
  img {
    height: 45px;
  }
}

@media screen and (min-width: 1200px) {
  .cs-main_header {
    position: relative;

    .container-fluid {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .cs-main_header_center,
  .cs-top_header_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cs-site_header.cs-style1 {
    .cs-main_header_center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .cs-main_header_left {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .cs-nav {
    display: flex;
    align-items: center;
    height: 100%;

    .cs-nav_list {
      display: flex !important;
      flex-wrap: wrap;
      height: inherit !important;

      > li {
        margin-right: 35px;
        height: inherit;

        &:last-child {
          margin-right: 0;
        }

        > a {
          padding: 10px 0;
          display: inline-flex;
          position: relative;
          height: inherit;
          align-items: center;
        }

        > ul {
          left: 0;
          top: calc(100% + 15px);
        }

        &:hover {
          > ul {
            top: 100%;
            opacity: 1;
            visibility: visible;
            transition: all 0.4s ease;
          }
        }
      }

      li:not(.cs-mega-menu) {
        position: relative;
      }

      ul {
        width: 260px;
        background-color: #fff;
        position: absolute;
        background-color: $white;
        box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
        border-top: 3px solid $accent;
        padding: 10px 0;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        display: block !important;
        transition: all 0.1s ease;

        li {
          &:hover {
            ul {
              top: 0px;
            }

            > ul {
              opacity: 1;
              visibility: visible;
              transition: all 0.4s ease;
            }
          }
        }

        a {
          font-size: 90%;
          display: block;
          line-height: 1.5em;
          padding: 12px 20px;
        }

        ul {
          top: 15px;
          left: 100%;
        }
      }
    }
  }
  .cs-munu_toggle,
  .cs-munu_dropdown_toggle {
    display: none;
  }

  .cs-nav .cs-nav_list {
    .cs-mega-wrapper {
      width: 1170px !important;
      left: 50%;
      transform: translateX(-50%);
      display: flex !important;
      position: absolute;
      padding: 0;

      > li {
        flex: 1;
        padding: 10px 0;

        &:not(:last-child) {
          border-right: 1px solid $border;
        }

        > a {
          color: $primary;
          font-weight: 600;

          &:hover {
            background-color: transparent;
          }
        }

        ul {
          position: initial;
          border: none;
          padding: 0;
          width: 100%;
          box-shadow: none;
          background-color: transparent;
        }
      }
    }

    .cs-mega-menu {
      &:hover {
        .cs-mega-wrapper {
          li ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .cs-nav
    .cs-nav_list
    > li
    ul:not(.cs-mega-wrapper)
    .menu-item-has-children
    > a {
    position: relative;
  }

  .cs-nav
    .cs-nav_list
    > li.menu-item-has-children
    ul:not(.cs-mega-wrapper)
    li.menu-item-has-children
    > a:after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 800;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    right: 16px;
  }
}

@media screen and (max-width: 1360px) {
  .cs-site-branding {
    max-width: 190px;
  }

  .cs-site_header_full_width .container {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 1199px) {
  .cs-munu_dropdown_toggle {
    position: absolute;
    height: 30px;
    width: 30px;
    right: 20px;
    top: 5px;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 10px;
      background-color: $secondary;
      transition: all 0.3s ease;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.active {
      &:before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  .cs-nav {
    .cs-nav_list {
      position: absolute;
      width: 100vw;
      left: -15px;
      background-color: $white;
      padding: 10px 0;
      display: none;
      top: 100%;
      border-top: 1px solid $border;
      border-bottom: 1px solid $border;
      overflow: auto;
      max-height: calc(100vh - 80px);
      line-height: 1.6em;

      ul {
        padding-left: 15px;
        display: none;
      }

      a {
        display: block;
        padding: 8px 20px;
      }
    }

    .menu-item-has-children {
      position: relative;
    }
  }

  /*Mobile Menu Button*/
  .cs-munu_toggle {
    display: inline-block;
    width: 30px;
    height: 27px;
    cursor: pointer;
    position: absolute;
    top: 27px;
    right: 30px;

    span,
    span:before,
    span:after {
      width: 100%;
      height: 2px;
      background-color: currentColor;
      display: block;
    }

    span {
      margin: 0 auto;
      position: relative;
      top: 12px;
      transition-duration: 0s;
      transition-delay: 0.2s;

      &:before {
        content: "";
        position: absolute;
        margin-top: -9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }

      &:after {
        content: "";
        position: absolute;
        margin-top: 9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }
    }
  }

  .cs-site_header.cs-style1 .cs-munu_toggle {
    top: 50%;
    right: 0px;
    margin-top: -13px;
  }

  .cs-toggle_active {
    span {
      background-color: rgba(0, 0, 0, 0);
      transition-delay: 0.2s;

      &:before {
        margin-top: 0;
        transform: rotate(45deg);
        transition-delay: 0s, 0.2s;
      }

      &:after {
        margin-top: 0;
        transform: rotate(-45deg);
        transition-delay: 0s, 0.2s;
      }
    }
  }

  .cs-header_toolbox {
    margin-right: 50px;
  }

  .cs-toolbox_btn {
    padding: 8px 20px;
    min-width: 100px;
  }

  .cs-nav .cs-nav_list a {
    position: relative;
  }

  .cs-site_header.cs-style1 .cs-main_header_in {
    height: 80px;
  }

  .cs-site_header.cs-style1 .cs-nav + .cs-toolbox {
    margin-left: 0;
  }

  .cs-site_header.cs-style1 .cs-toolbox {
    margin-left: 0;
    margin-right: 50px;
  }
  .cs-hamburger span {
    height: 3px;
  }
  .cs-site_header.cs-style1 {
    .cs-top_header {
      .cs-toolbox {
        margin-right: 0;
      }
    }
  }

  .cs-site_header .current-menu-item > a:before {
    display: none;
  }

  .cs-site_header.cs-style1.cs-type4 {
    .cs-main_header_in {
      padding: 0;
      box-shadow: none;
      border-radius: 0px;
      margin-top: 0;
      background-color: transparent;
    }
  }
  .cs-site_header.cs-style1 .cs-main_header_center {
    .cs-site_branding {
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: 991px) {
  .cs-site_header .container {
    max-width: 100%;
  }
  .cs-site_header.cs-style1 {
    .cs-action_box > *:not(:last-child) {
      margin-right: 25px;
    }
  }
}
@media screen and (max-width: 575px) {
  .cs-site-branding {
    max-width: 150px;
  }

  .cs-header_toolbox {
    margin-right: 44px;
  }

  .cs-toolbox > a:not(:first-child) {
    margin-left: 10px;
  }
  .cs-hamburger_wrap .cs-hamburger {
    margin-right: 0;
  }
  .cs-site_branding img {
    height: 32px;
  }
  .cs-site_header.cs-style1 .cs-toolbox > *:not(:first-child) {
    margin-left: 10px;
  }
  .cs-site_header.cs-style1 .cs-toolbox {
    margin-left: 0;
    margin-right: 45px;
  }
  .cs-site_header.cs-style1 .cs-btn {
    font-size: 14px;
    span {
      padding: 7px 15px;
    }
  }
  .cs-site_header.cs-style1 .cs-toolbox {
    margin-right: 40px;
  }
}

@media screen and (max-width: 450px) {
  .cs-toolbox > a:not(:first-child) {
    margin-left: 0;
  }
}
@media screen and (max-width: 340px) {
  .cs-site_header.cs-style1 .cs-btn {
    font-size: 13px;
    span {
      padding: 7px 10px;
    }
  }
}
